<template>
  <InfoDialog
    title="Status"
    anchor="top right"
    self="center left"
    :offset="[0, 0]"
    hide-header
  >
    <template #link>
      <q-chip
        dense
        :color="bgColor"
        text-color="white"
        class="flex items-center gap-2"
      >
        <Spinner v-if="viewData?.icon === 'spinner'" />
        <Icon
          v-if="viewData?.icon !== 'spinner'"
          class="text-white"
          :name="viewData?.icon"
          size="xs"
        />
        <span
          v-if="viewData?.label"
          class="ml-1!"
          style="font-size: 12px; margin-right: 2.5px"
        >
          {{ viewData?.label }}
        </span>
      </q-chip>
    </template>
    <template #default>
      <CardSection
        v-if="viewData?.cardMessage"
        :class="`bg-${viewData?.color}`"
        style="white-space: pre"
      >
        {{ viewData?.cardMessage }}
        <template v-if="shift">
          {{ transport }}
        </template>
      </CardSection>
    </template>
  </InfoDialog>
</template>

<script setup lang="ts">
import { useSocket } from "~/composables/useSocket.client";
import InfoDialog from "./InfoDialog.vue";
import { computed } from "vue";
import { useDarkMode } from "~/composables/useDarkMode";
import { useMagicKeys } from "#imports";
import { useOurNuxtApp } from "~/utils/nuxt";

const darkMode = useDarkMode();
const { isConnected, transport, error } = useSocket();
const { shift } = useMagicKeys();
const {
  $i18n: { t },
} = useOurNuxtApp();

const status = computed(() => {
  if (!isConnected.value && !error.value) return "loading";
  if (isConnected.value) return "connected";
  if (error.value) return "error";
  return "disconnected";
});

const bgColor = computed(() => {
  if (status.value === "connected") return "green-5";
  if (status.value === "error") return "red-5";
  return darkMode.value ? "grey-9" : "grey-3";
});

const data = computed<
  Record<
    typeof status.value,
    { icon: string; color: string; label?: string; cardMessage?: string }
  >
>(() => ({
  loading: {
    icon: "spinner",
    color: "grey-5",
    label: t("real_time.status_loading"),
  },
  connected: {
    icon: "mdi:cloud-check",
    color: "green-5",
    label: t("real_time.status_connected"),
    cardMessage: t("real_time.status_connected_message"),
  },
  disconnected: {
    icon: "mdi:cloud-off-outline",
    color: "red-5",
    cardMessage: t("real_time.status_disconnected_message"),
  },
  error: {
    icon: "mdi:cloud-off-outline",
    color: "red-5",
    label: t("real_time.status_error"),
    cardMessage: t("real_time.status_error_message", { error: error.value }),
  },
}));

const viewData = computed(() => data.value[status.value]);
</script>
